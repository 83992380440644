import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { breakpoints, palette } from 'lib/theme';
import { MAX_ATTEMPT_NUMBER, WebauthnCheck } from 'utils/webauthn/WebauthnCheck';
import { redirect } from 'utils/redirect';
import { LocationState, ToastType } from 'types/webauthn';
import { useLocation } from 'react-router-dom';
import { Webauthn } from 'utils/webauthn/Webauthn';
import { Button, Heading, Text, theme, useToast, window } from '@mtsbank/ui-kit';
import { REDIRECT_URL } from 'constants/index';
import { StateContext } from 'state';
import {
    showToastConnectEntranceByBioGTM,
    showScreenPermissionUseBioGTM,
    clickEnableUseBioGTM,
    clickToGoPersonalAccBioGTM,
    clickCancelUseClickBioGTM,
    repeatClickInErrorToastBioGTM,
    AuthTypeGTM,
    logFailedAuthGTM,
} from 'utils/GTM';
import { ToastButtons } from './ToastButtons';

const TIME_TO_REDIRECT = 300000; // 5 minutes in ms

export const WebauthnEnableForm: FC<{ webauthnCheck: WebauthnCheck }> = ({ webauthnCheck }) => {
    const { state } = useLocation<LocationState>();
    const { toast, removeToast } = useToast();
    const [isFetching, setFetching] = useState(false);
    const { login } = useContext(StateContext);

    const webauthn = useMemo(() => new Webauthn(), []);
    webauthn.setWebauthnCheck(webauthnCheck);

    const isNeedRedirect =
        (webauthnCheck.isNeedWebauthnEnable() && !webauthnCheck.getUserName()) ||
        (!webauthnCheck.isNeedWebauthnEnable() && webauthnCheck.isWebauthnEnabled());

    const doRedirect = useCallback(() => {
        if (state?.redirectUrl) {
            redirect(state.redirectUrl);
        } else {
            window.location.replace(REDIRECT_URL);
        }
        window.sessionStorage.clear();
    }, [state?.redirectUrl]);

    useEffect(() => {
        if (isNeedRedirect) {
            doRedirect();
        }
    }, [doRedirect, isNeedRedirect]);

    useEffect(() => {
        const timerId = window.setTimeout(doRedirect, TIME_TO_REDIRECT);

        return () => {
            window.clearTimeout(timerId);
        };
    }, [doRedirect]);

    const handleCancelClick = useCallback(() => {
        const quantityWebauthn = webauthnCheck.getQuantityWebauthn();
        const attemptNumber = quantityWebauthn.attemptNumber === MAX_ATTEMPT_NUMBER ? 1 : quantityWebauthn.attemptNumber + 1;

        webauthnCheck.setQuantityWebauthn({ attemptNumber });
        webauthnCheck.setWebauthn({ enabled: false, phone: webauthnCheck.getUserName() });

        clickCancelUseClickBioGTM({ login });
        doRedirect();
    }, [doRedirect, login, webauthnCheck]);

    const enableWebauthn = useCallback(() => {
        webauthn
            .enable()
            .then(() => {
                webauthnCheck.setWebauthn({ enabled: true, phone: webauthnCheck.getUserName() });

                showToastConnectEntranceByBioGTM({ login });

                doRedirect();
            })
            .catch((error) => {
                toast(ToastType.ERROR, 'Не\u00A0удалось подключить вход по\u00A0данным биометрии', '', {
                    buttons: (toastId) => (
                        <ToastButtons
                            type={ToastType.ERROR}
                            onContinueCLick={() => {
                                new Promise((resolve) => {
                                    removeToast(toastId);
                                    resolve(true);
                                }).then(() => {
                                    clickToGoPersonalAccBioGTM({ login });
                                    return doRedirect();
                                });
                            }}
                            onRepeatClick={() => {
                                removeToast(toastId);
                                enableWebauthn();
                                repeatClickInErrorToastBioGTM({ login });
                            }}
                        />
                    ),
                });

                logFailedAuthGTM(AuthTypeGTM.BIO, { login, error });
            })
            .finally(() => {
                setFetching(false);
            });
    }, [doRedirect, login, removeToast, toast, webauthn, webauthnCheck]);

    const handleEnableWebauthnClick = useCallback(() => {
        clickEnableUseBioGTM({ login });

        if (!isFetching) {
            enableWebauthn();
            setFetching(true);
        }
    }, [enableWebauthn, isFetching, login]);

    useEffect(() => {
        if (!isFetching && !isNeedRedirect) {
            showScreenPermissionUseBioGTM();
        }
    }, [isFetching, isNeedRedirect]);

    if (isNeedRedirect) {
        return null;
    }

    return (
        <OuterWrapper>
            <InnerWrapper>
                <Wrapper>
                    <StyledImage src="https://staticpayment.ssl.mts.ru/images/webauthn/face-touch-id.png" width="112" height="64" />
                    <Heading noMargin h={3} sizemob="md">
                        Использовать биометрию
                    </Heading>
                    <ListTitle>
                        <Text>Разрешите вход по&nbsp;отпечатку пальца или скану лица (Face ID&nbsp;/ Touch&nbsp;ID)</Text>
                    </ListTitle>
                    <List>
                        <ListItem>Быстро: без номера телефона</ListItem>
                        <ListItem>Безопасно: к&nbsp;уникальным данным нет доступа у&nbsp;других людей</ListItem>
                        <ListItem>Только для одного браузера и&nbsp;устройства</ListItem>
                    </List>
                    <ButtonsWrapper>
                        <Button variant="primary" onClick={handleEnableWebauthnClick} isLoading={isFetching}>
                            Разрешить
                        </Button>
                        <Button variant="secondary" onClick={handleCancelClick}>
                            Пропустить
                        </Button>
                    </ButtonsWrapper>
                </Wrapper>
            </InnerWrapper>
        </OuterWrapper>
    );
};

const Wrapper = styled.div`
    margin: 0 ${theme.spacings.xs4}px;

    & ul {
        display: flex;
        flex-direction: column;
        row-gap: ${theme.spacings.xs3}px;

        li {
            width: 100%;
        }
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacings.xs3}px;
    margin-top: 48px;

    @media (min-width: ${breakpoints.tablet}) {
        flex-direction: row;
        column-gap: ${theme.spacings.xs3}px;
    }
`;

const StyledImage = styled.img`
    margin-bottom: 24px;
`;

const ListItem = styled.li`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    ::marker {
        color: #bbc1c7;
    }
`;

const List = styled.ul`
    display: flex;
    row-gap: 8px;
    flex-direction: column;
    padding-left: 16px;
`;

const OuterWrapper = styled.div`
    width: 100%;
    padding: ${theme.spacings.xs}px;
    margin: auto;

    @media (min-width: ${breakpoints.tablet}) {
        max-width: 480px;
        margin-top: 72px;
        padding: ${theme.spacings.md}px;
    }
`;

const InnerWrapper = styled.div`
    background: ${palette.white};

    width: 100%;
    border-radius: 24px;

    @media (min-width: ${breakpoints.tablet}) {
        padding: 32px;
    }
`;

const ListTitle = styled.div`
    margin-top: 20px;
`;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Content } from 'components/common/Content';
import { Spacer } from 'components/common/Spacer';
import { Form } from 'components/common/Form';
import { LinkButton } from 'components/common/LinkButton';
import { PolicyText } from 'components/common/PolicyText';
import { Field } from 'components/fields/Field';
import { StateContext } from 'state';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { api, ResponseError } from 'utils/api';
import { apiUrls } from 'lib/apiUrls';
import { doSuccess } from 'utils/doSuccess';
import { getCyrilicLetters } from 'utils/getCyrilicLetters';
import { getNicknameCursorPosition } from 'utils/getCursorPosition';
import { stepParams } from 'lib/stepParams';
import { isWebview } from 'utils/isWebview';
import {
    clickBackGTM,
    furtherNickNameGTM,
    nicknameHandleErrorGTM,
    nicknameOnBlurGTM,
    showScreenMTSEcosystemGTM,
    ScreenNamesGTM,
    AuthTypeGTM,
    logFailedAuthGTM,
} from 'utils/GTM';

type FormValues = {
    nickname: string;
};

export const Nickname = () => {
    const {
        nickname: { heading, buttonText, inputError },
    } = stepParams;
    const { setDisplayStep, setLoading, getErrorModal, closeModal, login } = useContext(StateContext);
    const [isError, setError] = useState(false);
    const [resubmitting, setResubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        errors,
        clearErrors,
        setValue,
        formState: { isSubmitting },
        getValues,
    } = useForm<FormValues>();

    useEffect(() => {
        setLoading(isSubmitting || resubmitting);
        return () => {
            setLoading(false);
        };
    }, [isSubmitting, setLoading, resubmitting]);

    const onSubmit = useCallback(
        async (data: FormValues, e?: React.BaseSyntheticEvent) => {
            e?.preventDefault();
            furtherNickNameGTM({ login });

            const { nickname } = data;
            const requestData = {
                first_name: nickname,
                middle_name: '',
                last_name: '',
                email: '',
            };
            try {
                const result = await api.postJSON(apiUrls.register, requestData);
                if (result.redirect_url) {
                    doSuccess({ login, redirect_url: result.redirect_url, gtmProps: { authTypeGTM: AuthTypeGTM.NICKNAME } });
                }
            } catch (unknownError) {
                const error = unknownError as ResponseError;
                logFailedAuthGTM(AuthTypeGTM.NICKNAME, { error, login });
                setResubmitting(false);
                if (error.message === 'invalid_fio') {
                    setError(true);
                } else {
                    getErrorModal(
                        () => {
                            setResubmitting(true);
                            closeModal();
                            setTimeout(() => onSubmit(data, e), 300);
                        },
                        error,
                        ScreenNamesGTM.NAME_OR_NICKNAME
                    );
                }
            }
        },
        [closeModal, getErrorModal, login]
    );

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            clearErrors();
            setError(false);
            const { value } = e.target;
            const cursorPosition = e.target.selectionStart;
            const newValue = getCyrilicLetters(value).trim();

            if (newValue.length < value.length) {
                setError(true);
                nicknameHandleErrorGTM({ login });
            }

            setValue('nickname', newValue);

            const newCursorPosition = getNicknameCursorPosition(value, newValue, cursorPosition || 0);
            e.target.selectionStart = newCursorPosition;
            e.target.selectionEnd = newCursorPosition;
        },
        [clearErrors, setValue, login]
    );

    const onClearClick = useCallback(() => {
        setValue('nickname', '');
        clearErrors();
        setError(false);
    }, [clearErrors, setValue]);

    const onClickGoBack = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            clickBackGTM({ login, isNickName: true });
            setDisplayStep('login');
        },
        [login, setDisplayStep]
    );

    const handleBlurGTM = useCallback(() => {
        if (getValues('nickname') && !errors.nickname) {
            nicknameOnBlurGTM({ login });
        }
    }, [errors.nickname, getValues, login]);

    useEffect(() => {
        showScreenMTSEcosystemGTM();
    }, []);

    return (
        <Content heading={heading}>
            <Spacer regular height="1.5rem" />
            <Form
                onSubmit={handleSubmit(onSubmit)}
                buttonText={buttonText}
                error={errors.nickname?.message || inputError}
                isError={isError || errors.nickname !== undefined}
                isSubmitting={isSubmitting}
            >
                <Field
                    id="nickname"
                    type="text"
                    label="Как к вам обращаться?"
                    placeholder="Ваше имя"
                    register={register}
                    inputMode="text"
                    maxLength={50}
                    errorMessage={inputError}
                    isFocused={!isSubmitting}
                    isError={isError || errors.nickname !== undefined}
                    onChange={onChange}
                    onClick={onClearClick}
                    onBlurGTM={handleBlurGTM}
                />
            </Form>
            {isWebview() && <PolicyText login={login} />}
            <LinkButton onClick={onClickGoBack}>Назад</LinkButton>
        </Content>
    );
};

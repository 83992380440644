import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

/**
 * Хук для получения значения конкретного query-параметра из URL.
 *
 * @param key - Имя параметра, значение которого нужно получить.
 * @returns Значение параметра или undefined, если параметр отсутствует.
 */

// todo заменить по проекту дубли window... на этот хук
export const useGetQueryParam = (key: string): string | undefined => {
    const { search } = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(search);
        const value = params.get(key);
        return value === null ? undefined : value;
    }, [search, key]);
};

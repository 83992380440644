import { getPh, sendGmtEvent } from 'utils/GTM/common';
import {
    ActionGroupGTM,
    EventActionGTM,
    EventCategoryGTM,
    EventGTM,
    EventLabelsGTM,
    FilterName,
    PageTypeGTM,
    ParamsGTM,
    ScreenNamesGTM,
    UserAuthGTM,
} from 'utils/GTM/types';

const baseErrorParamsBio = {
    event: EventGTM.MTS_PAYMENTS_AUTH,
    eventCategory: EventCategoryGTM.AUTH_FORM,
    userAuth: UserAuthGTM.UNAUTHENTICATED,
    pageType: PageTypeGTM.SSO_AUTH,
};

/**  Ошибка авторизации при входе по WebAuthn
 *  событие срабатывает при показе тоста с ошибкой входа по 'WebAuthn'
 *  auth_form-error_show-vhod_biometriya-*/
export const showToastErrorLoginWithBioGTM = ({ login }: ParamsGTM) => {
    sendGmtEvent({
        ...baseErrorParamsBio,
        ph: getPh(login),
        eventAction: EventActionGTM.ERROR_SHOW,
        eventLabel: EventLabelsGTM.ENTRY_BY_BIOMETRIYA,
        screenName: ScreenNamesGTM.AUTH,
        filterName: FilterName.FAILED_LOGIN_BY_BIO,
        actionGroup: ActionGroupGTM.NON_INTERACTIONS,
    });
};

/**  при попытке подключения биометрики событие клика в toast с ошибкой,
 * событие срабатывает при клике на кнопку "повторить"
 * auth_form-click-povtorit */
export const repeatClickInErrorToastBioGTM = ({ login }: ParamsGTM) => {
    sendGmtEvent({
        ...baseErrorParamsBio,
        ph: getPh(login),
        eventAction: EventActionGTM.BUTTON_CLICK,
        eventLabel: EventLabelsGTM.REPEAT,
        screenName: ScreenNamesGTM.BIOMETRIYA,
        actionGroup: ActionGroupGTM.INTERACTIONS,
    });
};

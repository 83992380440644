import React, { createContext, useContext, useState, useEffect } from 'react';
import { SESSION_EXPIRED } from 'constants/index';

interface SessionContextValue {
    isSessionExpired: boolean;
    setSessionExpired: (expired: boolean) => void;
}

const SessionContext = createContext<SessionContextValue | undefined>(undefined);

export const SessionProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [isSessionExpired, setIsSessionExpired] = useState(false);

    const setSessionExpired = (expired: boolean) => {
        setIsSessionExpired(expired);
    };

    useEffect(() => {
        // Подписываемся на глобальное событие 'sessionExpired'
        const handleSessionExpired = () => {
            setIsSessionExpired(true);
        };

        window.addEventListener(SESSION_EXPIRED, handleSessionExpired);

        return () => {
            window.removeEventListener(SESSION_EXPIRED, handleSessionExpired);
        };
    }, []);

    return <SessionContext.Provider value={{ isSessionExpired, setSessionExpired }}>{children}</SessionContext.Provider>;
};

export const useSession = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
};

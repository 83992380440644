import React from 'react';
import { SessionProvider } from 'contexts/SessionContext';
import { AppContent } from './AppContent';

export const App = () => {
    return (
        <SessionProvider>
            <AppContent />
        </SessionProvider>
    );
};

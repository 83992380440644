import { baseFurtherParams, baseShowScreenParams, getPh, sendGmtEvent } from 'utils/GTM/common';
import {
    ActionGroupGTM,
    EventActionGTM,
    EventCategoryGTM,
    EventContentGTM,
    EventGTM,
    EventLabelsGTM,
    IdentificationCLickPropsGTM,
    PageTypeGTM,
    ParamsGTM,
    ScreenNamesGTM,
    UserAuthGTM,
} from 'utils/GTM/types';

/** Показ экрана "Ввод паспортных данных"
 * scrn
 * */
export const showScreenConfirmationPassportGTM = () =>
    sendGmtEvent({
        ...baseShowScreenParams,
        eventLabel: EventLabelsGTM.PASSPORT,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: ScreenNamesGTM.CONFIRMATION_PASSPORT,
    });

/** Показ экрана "Ввод номера карты МТС банка"
 * scrn
 * */
export const showScreenConfirmationToCardGTM = () =>
    sendGmtEvent({
        ...baseShowScreenParams,
        eventLabel: EventLabelsGTM.CARD_MSB,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: ScreenNamesGTM.CONFIRMATION_CARD,
    });

/** onBlur поля Ввод "серии и номера паспорта" или "номер карты"
 * auth_form-form_add-vvod_serii_i_nomera_pasporta
 * auth_form-form_add-nomer_karty
 * */
export const identityFieldOnBlurGTM = ({ login, isPassport }: IdentificationCLickPropsGTM) =>
    sendGmtEvent({
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventCategory: EventCategoryGTM.AUTH_FORM,
        eventAction: EventActionGTM.FORM_ADD,
        eventLabel: isPassport ? EventLabelsGTM.ENTRY_PASSPORT : EventLabelsGTM.ENTRY_CARD_NUMBER,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: isPassport ? ScreenNamesGTM.CONFIRMATION_PASSPORT : ScreenNamesGTM.CONFIRMATION_CARD,
        pageType: PageTypeGTM.SSO_AUTH,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });

/** Успешный ввод паспортных данных на экране подтверждения личности
 * auth_form-confirmed-dannye_pasporta */
export const successfulPassportConfirmedGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventCategory: EventCategoryGTM.AUTH_FORM,
        eventAction: EventActionGTM.FORM_CONFIRMED,
        eventLabel: EventLabelsGTM.PASSPORT_INFO,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: ScreenNamesGTM.CONFIRMATION_PASSPORT,
        pageType: PageTypeGTM.SSO_AUTH,
        actionGroup: ActionGroupGTM.NON_INTERACTIONS,
        ph: getPh(login),
    });

/** Успешный ввод номера карты на экране подтверждения личности
 * auth_form-confirmed-dannye_karty  */
export const successfulCardConfirmedGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventCategory: EventCategoryGTM.AUTH_FORM,
        eventAction: EventActionGTM.FORM_CONFIRMED,
        eventLabel: EventLabelsGTM.CARD_INFO,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: ScreenNamesGTM.CONFIRMATION_CARD,
        pageType: PageTypeGTM.SSO_AUTH,
        actionGroup: ActionGroupGTM.NON_INTERACTIONS,
        ph: getPh(login),
    });

/** Событие срабатывает каждый раз при клике по кнопке "Далее"
 *  auth_form-button_click-dalee-vvod_karty | auth_form-button_click-dalee-vvod_pasporta   */
export const furtherIdentityGTM = ({ login, isPassport }: IdentificationCLickPropsGTM) =>
    sendGmtEvent({
        ...baseFurtherParams,
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventCategory: EventCategoryGTM.AUTH_FORM,
        screenName: isPassport ? ScreenNamesGTM.CONFIRMATION_PASSPORT : ScreenNamesGTM.CONFIRMATION_CARD,
        eventContent: isPassport ? EventContentGTM.PASSPORT_ENTRY : EventContentGTM.CARD_ENTRY,
        ph: getPh(login),
    });

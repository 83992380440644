import 'core-js';
import 'lib/gib';

import React, { useEffect } from 'react';
import { AUTH_PATH, UPDATE_FORM_PATH, WEBAUTHN_PATH } from 'constants/index';
import { UpdateForm } from 'components/App/UpdateForm';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { OtpPage } from 'components/App/OtpPage';
import { ToastProvider } from '@mtsbank/ui-kit';
import { WebauthnPage } from 'components/App/WebauthnPage';
import { WebauthnProvider } from 'components/webauthn/WebauthnContext';
import { useInitGib } from 'utils/antiFrod';

export const AppContent = () => {
    const history = useHistory();
    const location = useLocation();

    useInitGib();

    useEffect(() => {
        const currentPath = location.pathname;
        const queryParams = location.search;

        if (currentPath === '/') {
            history.replace(`auth${queryParams}`);
        }
    }, [history, location.pathname, location.search]);

    return (
        <>
            <Route exact path={UPDATE_FORM_PATH}>
                <UpdateForm />
            </Route>
            <Route path={AUTH_PATH}>
                <OtpPage />
            </Route>
            <Route exact path={WEBAUTHN_PATH}>
                <ToastProvider className="toastProvider">
                    <WebauthnProvider>
                        <WebauthnPage />
                    </WebauthnProvider>
                </ToastProvider>
            </Route>
        </>
    );
};

import { useDidMount } from '@mtsbank/ui-kit';
import { uuid } from 'lib/generateUuid';
import { getAdditionalQueryParams } from 'utils/getAdditionalQueryParams';
import { useGetQueryParam } from 'hooks/useQuery';
import { SessionStorageKey } from 'types';
import { initGib } from './initGib';

export const useInitGib = () => {
    const tidFromQueryParam = useGetQueryParam(SessionStorageKey.TID);

    useDidMount(() => {
        const additionalParamTID = getAdditionalQueryParams().tid;
        const currentTid = tidFromQueryParam || additionalParamTID || sessionStorage.getItem(SessionStorageKey.TID) || uuid();
        sessionStorage.setItem(SessionStorageKey.TID, currentTid);

        // @ts-ignore: Unreachable code error
        const isAndroidSDK = Boolean(window?.AndroidSdk);
        // @ts-ignore: Unreachable code error
        const isIosSDK = Boolean(window?.webkit?.messageHandlers?.request_iOS_SDK);

        const init = () => {
            if (typeof window !== 'undefined') {
                if (typeof window.gib === 'undefined') {
                    window.onGIBLoad = () => initGib(currentTid);
                } else {
                    initGib(currentTid);
                }
            }
        };

        if (!isAndroidSDK && !isIosSDK) {
            init();
        }
    });
};

export const initGib = (id: string) => {
    const getGibCid = () => (window.location.host === 'sso.mtsbank.ru' ? 'mtsb-w-payment-sso' : 'mtsb-w-payment-sso-t');
    const gibCid = getGibCid();

    window.gib.init({
        cid: gibCid,
        backUrl: '//sb.mts.ru/api/fl', // todo вынести public key - EV-19479
        rsaModulus:
            'B1A3EFBBF4A831C72485B0016269814CDBADF78E4ED11F3F0D79E488F212F2422BBB7930FB63749C3B33FD67ADB6223896078D969E81CEC5A1A09775D746A45B8C911E1DF14CFE27A4F6608F06833AAEBFC2C18CFC327243D78B2BD5853A6247A66BE2725C9484504D6EA311572283B5CF55E95E9C533858B23B770ABC9C38CE6F4575606B389858FFC1DF205D362D0BC786970BF7B77C7E5D0792A49BB93A6B9E60066F962B9F6013E1788607BA731EA7B3789C2D22B65162CB82DF7E5023187E1CB6FDFB35B47E548EAC6E3AE679D0F6DD1F60C016EE17B68E7CBFFE41E4D8F34DB129BDFF43910EE0BC217874DF7394455D405E1FB2B04308137810121635',
    });
    window.gib.setAuthStatus(false);
    window.gibSessionId = id;
    window.gib.setSessionID(window.gibSessionId);
};

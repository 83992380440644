import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { doSuccess } from 'utils/doSuccess';
import { Identity, isIdentityType } from 'utils/formatIdentityInput';
import { Modal, ModalProps } from 'components/common/Modal';
import { Policy } from 'components/common/Policy';
import { Spinner } from 'components/common/Spinner';
import { breakpoints, palette } from 'lib/theme';
import { isStepType, getStep, StepType } from 'components/steps/Steps';
import { BlockAttemptsTime, StateContext } from 'state';
import { isRestrictedType, RestrictedType } from 'lib/restrictedParams';
import { modalsData } from 'lib/modalsData';
import { ReactComponent as WarnIcon } from 'assets/svg/iconWarn.svg';
import { getNumericValue } from 'utils/getNumericValue';
import { api, ResponseError } from 'utils/api';
import { apiUrls } from 'lib/apiUrls';
import { isWebview } from 'utils/isWebview';
import { getGeoposition } from 'utils/getGeoposition';
import { mapLink } from 'utils/getMapLink';
import { canLinkIntoPhone } from 'utils/appVersions';
import { sendEventToApp } from 'utils/sendEventToApp';
import { WebauthnCheck } from 'utils/webauthn/WebauthnCheck';
import { useHistory } from 'react-router-dom';
import { SESSION_EXPIRED, WEBAUTHN_PATH } from 'constants/index';
import { LocationState } from 'types/webauthn';
import { showPopUpConnectionErrorGTM, acceptPolicyGTM, AuthTypeGTM, logFailedAuthGTM } from 'utils/GTM';
import { SessionStorageKey } from 'types';
import { isNumber } from 'util';

const IDENTITY_BLOCK_TIME = 300000;
const DEFAULT_ATTEMPTS_ITEM = { login: '', time: '' };

const query = new URLSearchParams(window.location.search);
const phoneFromQuery = query.get('phone');

const renderStep = (stepType: StepType | null, identityType: Identity) => {
    if (!stepType) {
        return null;
    }

    const Component = getStep(stepType);

    return <Component type={identityType} />;
};

export const OtpPage = () => {
    const history = useHistory<LocationState>();
    const [login, setLogin] = useState('');
    const [identityAttempts, setIdentityAttempts] = useState(3);
    const [loading, setLoading] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayStep, setDisplayStep] = useState<StepType>('login');
    const [displayPolicyType, setDisplayPolicyType] = useState<'offer' | 'policy' | null>(null);
    const [isFormShown, setFormShown] = useState(true);
    const [identityType, setIdentityType] = useState<Identity>(Identity.id);
    const [modalProps, setModalProps] = useState<ModalProps>();
    const [restrictedType, setRestrictedType] = useState<RestrictedType>('wrongNumber');
    const [username, setUsername] = useState('');
    const sessionBlockAttempts = JSON.parse(sessionStorage.getItem('blockAttempts') || '{}');
    const initialBlockAttempts: BlockAttemptsTime = sessionBlockAttempts?.login ? sessionBlockAttempts : DEFAULT_ATTEMPTS_ITEM;
    const [blockAttempts, setBlockAttempts] = useState<BlockAttemptsTime>(initialBlockAttempts);

    useEffect(() => {
        if (isWebview() && !canLinkIntoPhone()) {
            if (!modalProps?.onSecondaryClick || !modalProps.secondaryButton) {
                return;
            }

            const { secondaryButton, onSecondaryClick, ...rest } = modalProps;
            const hasPrimaryCallButton = modalProps?.onPrimaryClick === 'callToBank';
            const additionalParams = hasPrimaryCallButton ? { primaryButton: secondaryButton, onPrimaryClick: onSecondaryClick } : {};

            setModalProps({
                ...rest,
                ...additionalParams,
            });
        }
    }, [modalProps]);

    const toggleScrollLock = useCallback(() => {
        document.documentElement.classList.toggle('scroll-lock');
        document.body.classList.toggle('scroll-lock');
    }, []);

    const toggleModal = useCallback(() => {
        toggleScrollLock();
        setDisplayModal((prevState) => !prevState);
    }, [toggleScrollLock]);

    const closeModal = useCallback(() => {
        toggleScrollLock();
        setDisplayModal(false);
    }, [toggleScrollLock]);

    const updateClient = useCallback(
        (step: StepType = 'login', type?: RestrictedType) => (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();

            if (type) {
                setRestrictedType(type);
            }

            closeModal();
            setDisplayStep(step);
        },
        [closeModal]
    );

    const getErrorModal = useCallback(
        (fn: () => void, error: ResponseError) => {
            const { messageCode, description, status, id, message } = error;
            const errorID = status === 500 ? id : null; // TODO какая то старая логика, перенес из параметров DRY
            showPopUpConnectionErrorGTM({ messageCode, description, login });
            const isSessionExipred = message.includes(SESSION_EXPIRED);
            const defaultModalsData = isSessionExipred ? modalsData.sessionExpired : modalsData.networkError;
            setModalProps({
                ...defaultModalsData,
                ...(!errorID && !isSessionExipred && { message: '' }), // TODO: костыль чтоб сохранить старый функционал и добавить новый, в дальнейшем в рамках переработки экранов ошибок нужно отрефакторить
                icon: <WarnIcon />,
                onPrimaryClick: isSessionExipred
                    ? () => {
                          setDisplayModal(false);
                      }
                    : fn,
                secondaryButton: errorID ? 'Позвонить в банк' : '',
                onSecondaryClick: errorID ? 'callToBank' : undefined,
                ...(errorID && { errorID }),
            });
            toggleModal();
        },
        [login, toggleModal]
    );

    const openOffer = useCallback(() => {
        setDisplayPolicyType('offer');
        setFormShown(false);
        toggleScrollLock();
    }, [toggleScrollLock]);

    const openPolicy = useCallback(() => {
        setDisplayPolicyType('policy');
        setFormShown(false);
        toggleScrollLock();
    }, [toggleScrollLock]);

    const closePolicy = useCallback(() => {
        setFormShown(true);
        setDisplayPolicyType(null);
        toggleScrollLock();
        setDisplayStep('login');
    }, [toggleScrollLock]);

    const lockModalProps = useMemo<ModalProps>(
        () => ({
            ...modalsData.loginLock,
            onPrimaryClick: 'callToBank',
            onSecondaryClick: (e) => {
                sendEventToApp();
                updateClient()(e);
            },
        }),
        [updateClient]
    );

    /**
     * Временное решение поправить баг вечной блокировки сессии в Identity SITE-3987,
     * отрефачить: завязать логику сессии к конкретному аккаунту
     */
    const getIsBlockedTime = (time: string, isCurrentLogin: boolean) => {
        const passedTime = Math.floor(Math.abs(Date.now() - Number(time)));
        return passedTime <= IDENTITY_BLOCK_TIME && isCurrentLogin;
    };

    /**
     * Взято из OtPts
     * потом выпилить
     */
    const setLinkToMap = useCallback(() => {
        const geoposition = getGeoposition();
        return mapLink(geoposition || {});
    }, []);

    /**
     * Взято из OtPts
     * потом выпилить
     */
    const afModalProps = useMemo<ModalProps>(
        () => ({
            ...modalsData.smsLock,
            type: 'af',
            onPrimaryClick: 'callToBank',
            onSecondaryClick: setLinkToMap,
        }),
        [setLinkToMap]
    );

    const acceptPolicy = useCallback(async () => {
        setLoading(true);
        acceptPolicyGTM({ login });
        const tid = sessionStorage.getItem(SessionStorageKey.TID);

        return api
            .postJSON(apiUrls.signPolicy, { sign: true, tid })
            .then(({ redirect_url }) => {
                if (redirect_url) {
                    doSuccess({ login, redirect_url, gtmProps: { authTypeGTM: AuthTypeGTM.SIGN_POLICY } });
                } else {
                    setDisplayPolicyType(null);
                    toggleScrollLock();
                    setFormShown(true);
                    setDisplayStep('confirmation');
                }
            })
            .catch((unknownError) => {
                const error = unknownError as ResponseError;
                setLoading(false);
                logFailedAuthGTM(AuthTypeGTM.SIGN_POLICY, { login, error });

                if (isNumber(error.status) && [400, 403].includes(error.status)) {
                    setLogin('');
                    setDisplayStep('login');
                    setFormShown(true);
                    setModalProps(error.status === 400 ? lockModalProps : afModalProps);
                    toggleModal();
                } else {
                    getErrorModal(() => {
                        closeModal();
                        setLoading(true);
                        setTimeout(() => acceptPolicy(), 300);
                    }, error);
                }
            });
    }, [login, toggleScrollLock, lockModalProps, afModalProps, toggleModal, getErrorModal, closeModal]);

    const showOTP = useCallback(
        async (phoneNumber = '') => {
            setLoading(true);
            const phone = phoneNumber.length > 0 ? getNumericValue(phoneNumber) : getNumericValue(login);

            window.ineum('user', phone);
            window.gib.setAttribute('failedSignInAttempt', phone, { encryption: 'rsa' });

            try {
                const data = { login: phone };
                const result = await api.postJSON(apiUrls.login, data);

                if (result.name) {
                    setUsername(result.name);
                }

                if (result.sign_policy) {
                    sessionStorage.setItem('sign_policy', result.sign_policy);
                }

                if (result.next_step === 'check_additional_data') {
                    setDisplayPolicyType(null);
                    toggleScrollLock();
                    setFormShown(true);
                    setDisplayStep('confirmation');
                    setLoading(false);

                    return;
                }

                setLoading(false);
                setFormShown(true);
                sessionStorage.setItem('counter', result.count);
                sessionStorage.setItem('attempts', result.count_confirm);
                setDisplayStep('otp');
            } catch (unknownError) {
                const error = unknownError as ResponseError;
                const { status } = error;
                logFailedAuthGTM(AuthTypeGTM.OTP, { login, error });
                setLoading(false);
                if (status === 400 || status === 403) {
                    setLogin('');
                    setDisplayStep('login');
                    setFormShown(true);
                    setModalProps(status === 400 ? lockModalProps : afModalProps);
                    toggleModal();
                } else {
                    getErrorModal(() => {
                        closeModal();
                        setLoading(true);
                        setTimeout(() => showOTP(phoneNumber), 300);
                    }, error);
                }
            }
        },
        [login, toggleScrollLock, lockModalProps, afModalProps, toggleModal, getErrorModal, closeModal]
    );

    const appState = useMemo(
        () => ({
            login,
            identityAttempts,
            restrictedType,
            username,
            displayPolicyType,
            setLogin,
            setIdentityAttempts,
            setDisplayStep,
            setLoading,
            setIdentityType,
            setModalProps,
            setRestrictedType,
            setUsername,
            getErrorModal,
            toggleModal,
            toggleScrollLock,
            closeModal,
            updateClient,
            openOffer,
            openPolicy,
            showOTP,
            getIsBlockedTime,
            blockAttempts,
            setBlockAttempts,
        }),
        [
            closeModal,
            displayPolicyType,
            getErrorModal,
            identityAttempts,
            login,
            openOffer,
            openPolicy,
            restrictedType,
            showOTP,
            toggleModal,
            toggleScrollLock,
            updateClient,
            username,
            blockAttempts,
        ]
    );

    useEffect(() => {
        if (phoneFromQuery && phoneFromQuery.length === 11) {
            setLogin(phoneFromQuery);
            showOTP(phoneFromQuery);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // check storage data and update state on mount
    useEffect(() => {
        if (!sessionStorage.displayStep) {
            sessionStorage.setItem('displayStep', 'login');
        }

        Object.keys(sessionStorage).forEach((key) => {
            const newState = sessionStorage.getItem(key);

            if (!newState) {
                return;
            }

            switch (key) {
                case 'login': {
                    setLogin(newState);

                    break;
                }
                case 'displayStep': {
                    if (isStepType(newState)) {
                        setDisplayStep(newState);
                    }

                    break;
                }
                case 'identityType': {
                    if (isIdentityType(newState)) {
                        setIdentityType(newState);
                    }

                    break;
                }
                case 'identityAttempts': {
                    setIdentityAttempts(Number(newState));

                    break;
                }
                case 'restricted': {
                    if (isRestrictedType(newState)) {
                        setRestrictedType(newState);
                    }

                    break;
                }
                case 'username': {
                    setUsername(newState);

                    break;
                }
                default: {
                    break;
                }
            }
        });
    }, []);

    // update current step displaying
    useEffect(() => {
        sessionStorage.setItem('displayStep', displayStep);
        sessionStorage.setItem('login', login);
    }, [displayStep, login]);

    // update identity attempts
    useEffect(() => {
        sessionStorage.setItem('identityAttempts', identityAttempts.toString());
    }, [identityAttempts]);

    // update identity type
    useEffect(() => {
        sessionStorage.setItem('identityType', identityType || '');
    }, [identityType]);

    // update restricted type
    useEffect(() => {
        sessionStorage.setItem('restricted', restrictedType || '');
    }, [restrictedType]);

    // update username
    useEffect(() => {
        sessionStorage.setItem('username', username);
    }, [username]);

    const isRedirectToWebauthn = !isWebview() && new WebauthnCheck().isWebauthnEnabled() && !history.location.state?.webauthn;
    // redirect to webauthn for web app if webauthn enabled
    useEffect(() => {
        if (isRedirectToWebauthn) {
            history.push(WEBAUTHN_PATH, { webauthn: true });
        }
    }, [history, isRedirectToWebauthn]);

    useEffect(() => {
        if (history.location.state?.stepType === 'confirmation') {
            setDisplayPolicyType(null);
            toggleScrollLock();
            setFormShown(true);
            setDisplayStep('confirmation');
        }
    }, [history.location.state?.stepType, toggleScrollLock]);

    if (isRedirectToWebauthn) {
        return null;
    }

    return (
        <StateContext.Provider value={appState}>
            <AppStyled>
                <AppCard isShown={isFormShown}>
                    {loading && <Spinner isBlock />}
                    {renderStep(displayStep, identityType)}
                </AppCard>
                {displayModal && modalProps && (
                    <Modal
                        heading={modalProps.heading}
                        message={modalProps.message}
                        icon={modalProps.icon}
                        primaryButton={modalProps.primaryButton}
                        secondaryButton={modalProps.secondaryButton}
                        onPrimaryClick={modalProps.onPrimaryClick}
                        onSecondaryClick={modalProps.onSecondaryClick}
                    />
                )}
                {displayPolicyType && (
                    <Policy closePolicy={closePolicy} acceptPolicy={acceptPolicy} type={displayPolicyType} login={login}>
                        {loading && <Spinner isBlock />}
                    </Policy>
                )}
            </AppStyled>
            {!isWebview() && (
                <Offer>
                    © {new Date().getFullYear()} ПАО «МТС» и ПАО «МТС Банк». Все права защищены.
                    <StyledBr />
                    При входе на ресурс, вы принимаете{' '}
                    <Link
                        href="https://static.ssl.mts.ru/mts_rf/images/usloviya-edinogo-dostupa-k-servisam-MTS.html"
                        target="_blank"
                        rel="nofollow norefferrer noopener"
                    >
                        условия доступа
                        <span> и </span>
                        политику конфиденциальности
                    </Link>
                </Offer>
            )}
        </StateContext.Provider>
    );
};

const AppCard = styled.main<{ isShown: boolean }>`
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    width: 100%;
    @media (min-width: ${breakpoints.tablet}) {
        position: relative;
        padding: 3rem;
        border-radius: 8px;
        background-color: ${palette.white};
        width: 23rem;
    }
`;

const AppStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 1.5rem 1.5rem 0;
    margin: 0;
    white-space: pre-wrap;

    @media (min-width: ${breakpoints.mobileL}) {
        padding: 1.5rem 0 0;
        width: 21.625rem;
    }

    @media (min-width: ${breakpoints.tablet}) {
        padding: 1.5rem 0;
        margin: 3rem 0;
        width: 23rem;
    }
`;

const StyledBr = styled.br`
    display: none;

    @media (min-width: ${breakpoints.tablet}) {
        display: block;
    }
`;

const Link = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: ${palette.blue};
    font-size: 0.875rem;
`;

const Offer = styled.div`
    text-align: left;
    font-size: 14px;
    margin: 24px;
    color: #626c77;

    @media (min-width: ${breakpoints.tablet}) {
        text-align: center;
        margin: 20px;
    }
`;

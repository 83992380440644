import Cookies from 'js-cookie';
import { SESSION_EXPIRED } from 'constants/index';

/**
 * Проверяет, истекла ли сессия на основе значения куки `go_session_sample`.
 * Возвращает `true`, если сессия истекла, либо кука отсутствует или некорректна.
 */
export const getIsSessionExpired = () => {
    // 1. Проверяем, что кука вообще есть
    const cookieStr = Cookies.get('go_session_sample');
    if (!cookieStr) {
        // Нет куки — считаем, что сессия отсутствует => «истекла»
        return true;
    }

    // Пример куки:
    // "2024-12-17 10:24:09.537928044 +0300 MSK m=+594259.993125107"
    // Иногда «хвост» "m=+594259..." мешает парсингу, поэтому его отрезаем.
    const trimmed = cookieStr.split('m=+')[0].trim();
    // trimmed => "2024-12-17 10:24:09.537928044 +0300 MSK"

    // 2. Разбиваем на части по пробелам: дата, время, смещение, аббревиатура
    const parts = trimmed.split(' ');
    // parts[0] = "2024-12-17"
    // parts[1] = "10:24:09.537928044" (с микросекундами)
    // parts[2] = "+0300"
    // parts[3] = "MSK" (опционально)

    if (parts.length < 3) {
        // Формат куки не совпадает с ожидаемым => считаем истёкшей
        return true;
    }

    // 3. Обрабатываем время: убираем микросекунды
    const datePart = parts[0]; // "2024-12-17"
    const timePartFull = parts[1]; // "10:24:09.537928044"
    const offsetPart = parts[2]; // "+0300"

    // Если надо игнорировать микросекунды, оставляем только HH:mm:ss
    const timePart = timePartFull.split('.')[0]; // "10:24:09"

    // 4. Формируем ISO-строку: "YYYY-MM-DDTHH:mm:ss±HHmm"
    // "2024-12-17T10:24:09+0300"
    const isoString = `${datePart}T${timePart}${offsetPart}`;

    // 5. Парсим через нативный конструктор Date
    // JS корректно учтёт смещение +0300 и переведёт время в UTC
    const parsedDate = new Date(isoString);
    if (Number.isNaN(parsedDate.getTime())) {
        // Если дата невалидна, сессия считается истекшей
        return true;
    }

    // 6. Сравниваем распарсенное время с текущим
    const now = new Date();
    // Если parsedDate < now — значит время истекло.
    return parsedDate < now;
};

export const dispatchSessionExpired = () => {
    const event = new Event(SESSION_EXPIRED);
    window.dispatchEvent(event);
};
